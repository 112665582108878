<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="employees"
          :search="search"
          title="Pegawai"
          subtitle="Halaman untuk melihat data pegawai"
          :no-add-button="true"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @change-page="paginationHandler"
          @show-image="showImage"
          @live-search="searchHandler"
          @show-detail="detailCertificate"
          @show-detail-employee="detailEmployee($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialogEmployee"
      :width="800"
      header="DATA LENGKAP PEGAWAI"
      :hidden-actions="false"
      @close-modal="modalDialogEmployee = false"
      @close-button="modalDialogEmployee = false"
    >
      <template
        v-slot:body
      >
        <div class="d-flex justify-center mb-2">
          <div>
            <v-img
              width="150"
              src="@/assets/images/avatars/ari.png"
            ></v-img>
          </div>
        </div>
        <div v-if="!isLoadingModal">
          <div
            v-for="data,index in datas"
            :key="index"
            class="d-flex py-3 px-2"
            style="border-bottom: 1px solid #ddd"
          >
            <div style="width: 20%">
              {{ data.title }}
            </div>
            <div class="ml-8">
              : {{ data.content }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center py-10"
        >
          <v-progress-circular
            indeterminate
            :size="50"
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalCertificate"
      :hidden-actions="false"
      header="Sertifikat Guru"
      @close="modalCertificate = false"
      @close-modal="modalCertificate = false"
    >
      <template v-slot:header>
      </template>
      <template
        v-if="certificates.length"
        v-slot:body
      >
        <div
          v-for="certificate,index in certificates"
          :key="index"
        >
          <div
            class="d-flex py-3 align-center justify-space-between"
          >
            <div>
              <p class="mb-0 font-semibold">
                {{ certificate.name }}
              </p>
              <span>Nomor : {{ certificate.description }}</span>
            </div>
            <div>
              <v-chip
                v-if="checkExtension(certificate.file) === 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                :href="certificate.file"
                target="_blank"
              >
                Lihat Sertifikat
              </v-chip>
              <v-chip
                v-if="checkExtension(certificate.file) !== 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                @click="showCertificate(certificate.file)"
              >
                Lihat Sertifikat
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </template>
      <template
        v-else
        v-slot:body
      >
        Belum ada sertifikat
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="showImageCertificate"
      :width="800"
      @close="showImageCertificate = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeShowCertificate"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="certificate.file"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { integerValidator, required } from '@core/utils/validation'
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'FunctionaryEmployee',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    ModalDialog,
    ImageOverlay,
    MainCard,
  },
  data() {
    return {
      page: 1,
      search: '',
      dialog: null,
      width: 600,
      totalPages: 0,
      totalItems: 0,
      modalDialogEmployee: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingModal: false,
      overlay: false,
      imageOverlay: false,
      icons: {
        mdiClose,
        mdiImageMultipleOutline,
      },
      formData: null,
      headers: [
        { text: 'No', value: 'index' },
        // { text: 'Nama Lengkap', value: 'employeeNameAndPhoto' },
        { text: 'NIP', value: 'nip' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Jabatan', value: 'status_work' },
        { text: 'Alamat', value: 'place.address' },
        { text: 'Total Sertifikat', value: 'total_certificate' },
        { text: 'Sertifikat', value: 'detail' },
        { text: '', value: 'detail-employee' },
      ],
      datas: [
        { title: 'NIK', keyName: 'nis', content: 'nik' },
        { title: 'NIP', keyName: 'nisn', content: 'nip' },
        { title: 'Nama Lengkap', keyName: 'name', content: 'name' },
        { title: 'Gelar Pertama', keyName: 'first_degree', content: 'first_degree' },
        { title: 'Gelar Terakhir', keyName: 'last_degree', content: 'last_degree' },
        { title: 'Tanggal Lahir', keyName: 'date_of_birth', content: 'date_of_birth' },
        { title: 'Tempat Lahir', keyName: 'place_of_birth', content: 'place_of_birth' },
        { title: 'Jenis Kelamin', keyName: 'gender', content: 'gender' },
        { title: 'Agama', keyName: 'religion', content: 'religion' },
        { title: 'Status Pernikahan', keyName: 'marital_status', content: 'marital_status' },
        { title: 'Telepon Rumah', keyName: 'phone_house', content: 'phone_house' },
        { title: 'No HP', keyName: 'phone', content: 'phone' },
        { title: 'Tanggal Kerja', keyName: 'date_of_work', content: 'date_of_work' },
        { title: 'Status Pekerjaan', keyName: 'status_work', content: 'status_work' },
      ],
      school_uuid: null,
      school: [],
      modalDialogImport: false,
      modalCertificate: false,
      showImageCertificate: false,
      certificates: [],
      certificate: {
        file: '',
      },
      filterQuery: {
        employee: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.listEmployee(this.filterQuery)
      },
    },
  },
  beforeMount() {
    this.getSchoolUuid()
  },
  mounted() {
    this.listEmployee()
  },
  methods: {
    async showOverlay(uuid) {
      this.overlay = !this.overlay
      this.employee.uuid = uuid
    },
    showFormImport() {
      this.modalDialogImport = true
    },
    async showImage(uuid) {
      await this.$services.ApiServices.get('employee', uuid).then(
        ({ data }) => {
          this.employee = data.data
          this.employee.photo = data.data.photo
        },
        err => console.error(err),
      )
      this.imageOverlay = true
    },
    async closeImage() {
      this.overlay = false
      this.imageOverlay = false
    },

    async listEmployee(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('employee', { ...params, page: this.page, school: this.school_uuid }).then(
        ({ data }) => {
          this.employees = data.data.map((employees, index) => ({
            ...employees,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    checkExtension(certificate) {
      const file = certificate.split('.')

      const last = file[file.length - 1]

      return last
    },
    async detailCertificate(uuid) {
      await this.$services.ApiServices.get('employee', uuid).then(({ data }) => {
        this.certificates = data.data.certificate
      })
      this.modalCertificate = true
    },
    async showCertificate(file) {
      this.certificate.file = file
      this.modalCertificate = false
      this.showImageCertificate = true
    },
    async detailEmployee(uuid) {
      this.modalDialogEmployee = true
      this.isLoadingModal = true
      await this.$services.ApiServices.get('employee', uuid).then(({ data }) => {
        Object.keys(data.data).forEach((key, el) => {
          // console.log(el)
          this.datas.forEach(el => {
            console.log(el)
            if (el.keyName === key) {
              el.content = data.data[key]
            }
          })
        })
      })
      this.isLoadingModal = false
    },
    async closeShowCertificate() {
      this.overlay = false
      this.showImageCertificate = false
      this.modalCertificate = true
    },
    searchHandler(employee) {
      this.filterQuery.employee = employee
      this.page = 1
      this.listEmployee(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
  },
}
</script>

<style>
</style>
