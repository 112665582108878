<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          title="Data Guru"
          subtitle="Halaman untuk melihat total seluruh guru"
          :total-items="totalItems"
          :headers="headers"
          :items="teachers"
          :search="search"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          :no-add-button="true"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @show-detail="detailCertificate"
          @show-detail-teacher="detailTeacher($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else>
    </SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialogTeacher"
      :width="800"
      header="DATA LENGKAP GURU"
      :hidden-actions="false"
      @close-modal="modalDialogTeacher = false"
      @close-button="modalDialogTeacher = false"
    >
      <template
        v-slot:body
      >
        <div class="d-flex justify-center mb-2">
          <div>
            <v-img
              width="150"
              src="@/assets/images/avatars/ari.png"
            ></v-img>
          </div>
        </div>
        <div v-if="!isLoadingModal">
          <div
            v-for="data,index in datas"
            :key="index"
            class="d-flex py-3 px-2"
            style="border-bottom: 1px solid #ddd"
          >
            <div style="width: 20%">
              {{ data.title }}
            </div>
            <div class="ml-8">
              : {{ data.content }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-center align-center py-10"
        >
          <v-progress-circular
            indeterminate
            :size="50"
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalCertificate"
      header="Sertifikat Guru"
      :hidden-actions="false"
      @close="modalCertificate = false"
      @close-modal="modalCertificate = false"
    >
      <template
        v-if="certificates.length"
        v-slot:body
      >
        <div
          v-for="certificate,index in certificates"
          :key="index"
        >
          <div
            class="d-flex py-3 align-center justify-space-between"
          >
            <div>
              <p class="mb-0 font-semibold">
                {{ certificate.name }}
              </p>
              <span>Nomor : {{ certificate.description }}</span>
            </div>
            <div>
              <v-chip
                v-if="checkExtension(certificate.file) === 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                :href="certificate.file"
                target="_blank"
              >
                Lihat Sertifikat
              </v-chip>
              <v-chip
                v-if="checkExtension(certificate.file) !== 'pdf'"
                color="success"
                class="v-chip-light-bg success--text"
                @click="showCertificate(certificate.file)"
              >
                Lihat Sertifikat
              </v-chip>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </template>
      <template
        v-else
        v-slot:body
      >
        Belum ada sertifikat
      </template>
    </ModalDialog>
    <ImageOverlay
      :visible="imageOverlay"
      :width="800"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="certificate.file"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import { mdiArrowLeft, mdiClose } from '@mdi/js'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import ImageOverlay from '@/views/components/ImageOverlay.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'SchoolDataTeacher',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    ImageOverlay,
    SkeletonLoaderTable,
  },
  data() {
    return {
      teachers: [],
      student: {},
      icons: { mdiArrowLeft, mdiClose },
      search: '',
      modalCertificate: false,
      modalDialogTeacher: false,
      imageOverlay: false,
      overlay: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingData: false,
      isLoadingModal: false,
      isLoadingTable: true,
      teacher_uuid: '',
      service: 'teacher',
      headers: [
        { text: '#', value: 'index' },
        { text: 'NIP', value: 'employee.nip' },
        { text: 'NIK', value: 'employee.nik' },
        { text: 'Nama', value: 'employee.name' },
        { text: 'Jabatan', value: 'employee.status_work' },
        { text: 'Foto', value: 'thumbnail' },
        { text: 'Sertifikat', value: 'employee.total_certificate' },
        { text: '', value: 'detail-teacher' },
      ],
      datas: [
        { title: 'NIK', keyName: 'nis', content: 'nik' },
        { title: 'NIP', keyName: 'nisn', content: 'nip' },
        { title: 'Nama Lengkap', keyName: 'name', content: 'name' },
        { title: 'Gelar Pertama', keyName: 'first_degree', content: 'first_degree' },
        { title: 'Gelar Terakhir', keyName: 'last_degree', content: 'last_degree' },
        { title: 'Tanggal Lahir', keyName: 'date_of_birth', content: 'date_of_birth' },
        { title: 'Tempat Lahir', keyName: 'place_of_birth', content: 'place_of_birth' },
        { title: 'Jenis Kelamin', keyName: 'gender', content: 'gender' },
        { title: 'Agama', keyName: 'religion', content: 'religion' },
        { title: 'Status Pernikahan', keyName: 'marital_status', content: 'marital_status' },
        { title: 'Telepon Rumah', keyName: 'phone_house', content: 'phone_house' },
        { title: 'No HP', keyName: 'phone', content: 'phone' },
        { title: 'Tanggal Kerja', keyName: 'date_of_work', content: 'date_of_work' },
        { title: 'Status Pekerjaan', keyName: 'status_work', content: 'status_work' },
      ],
      certificates: [],
      certificate: {
        file: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.getTeacher()
      },
    },
    search: {
      handler() {
        this.getTeacher()
      },
    },
  },
  beforeMount() {
    this.getSchoolUuid()
  },
  mounted() {
    this.getTeacher()
  },
  methods: {
    async getTeacher(params = {}) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        search: this.search,
        page: this.page,
      }).then(({ data }) => {
        this.teachers = data.data.map((teacher, index) => ({
          index: index + 1,
          ...teacher,
        }))
        this.teachers.forEach(el => {
          this.teacher_uuid = el.uuid
        })
        this.isLoadingTable = false
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    checkExtension(certificate) {
      const file = certificate.split('.')

      const last = file[file.length - 1]

      return last
    },
    async detailCertificate(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.certificates = data.data.employee.certificate
        this.certificate.file = this.certificates.forEach(el => {
          this.certificate.file = el
        })
      })
      this.modalCertificate = true
    },
    async showCertificate(file) {
      this.certificate.file = file
      this.modalCertificate = false
      this.imageOverlay = true
    },
    async detailTeacher(uuid) {
      this.modalDialogTeacher = true
      this.isLoadingModal = true
      await this.$services.ApiServices.get('employee', uuid).then(({ data }) => {
        Object.keys(data.data).forEach((key, el) => {
          // console.log(el)
          this.datas.forEach(el => {
            console.log(el)
            if (el.keyName === key) {
              el.content = data.data[key]
            }
          })
        })
      })
      this.isLoadingModal = false
    },

    closeImage() {
      this.overlay = false
      this.imageOverlay = false
      this.modalCertificate = true
    },
    searchHandler(data) {
      this.search = data
    },
    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
